import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import ReactSelect from 'components/common/react_select'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import API from 'services/api'
import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'

const I18N = i18nPath('views.admin.journeys.step_templates')

const SPECIFIC_GROUP_ROLE = 'specific_group'

const CorrespondentSelect = ({
  type,
  updateWorkingCopy,
  attributes,
  isDisabled = false,
  journeyBlueprintType = 'onboarding',
  showCompanyCorrespondents = true,
  showOnlySpecificEmployee = false,
  isCustomStep = false,
  forUser = null,
  isTaskable = false,
}) => {
  const location = useLocation()
  const currentCompany = useCurrentCompany()
  const { settings, groupDisplayName, appName } = currentCompany
  const isOnboardingBuddiesEnabled = settings?.journeys?.onboardingBuddies?.enabled
  const isOnboardingJourney = journeyBlueprintType === 'onboarding'
  const isOffboardingJourney = journeyBlueprintType === 'offboarding'
  const isCustomJourney = journeyBlueprintType === 'custom'
  const isBuddyAssignmentTask = location.pathname.includes('buddy_assignment_templates')

  const selectedRole = attributes?.role

  const offboardingEmployeeOption = { value: 'offboarding_employee', label: isCustomStep && forUser ? forUser.preferredFullName : I18N('offboarding_employee') }
  const onboardingEmployeeOption = { value: 'new_hire', label: isCustomStep && forUser ? forUser.preferredFullName : I18N('new_hire') }
  const employeeOnJourneyOption = { value: 'employee_on_journey', label: isCustomStep && forUser ? forUser.preferredFullName : I18N('employee_on_journey') }

  const { data: groupCorrespondents = [] } = useGlobalStateBucket(
    () => API.admin.journey.correspondents.fetchAll({ role: SPECIFIC_GROUP_ROLE }),
    'groupCorrespondents',
    settings.groups.enabled && isTaskable
  )

  const groupOptions = useMemo(() => groupCorrespondents.map(correspondent => (
    { id: correspondent?.group?.id, name: correspondent?.group?.name }
  )), [groupCorrespondents])

  const showGroupOption = settings.groups.enabled && isTaskable && groupCorrespondents.length > 0

  const options = [
    type === 'from' && showCompanyCorrespondents && { value: 'app', label: appName },
    type === 'from' && showCompanyCorrespondents && appName !== currentCompany.name && { value: 'company', label: currentCompany.name },
    type === 'to' && isOnboardingJourney && !isBuddyAssignmentTask && onboardingEmployeeOption,
    type === 'to' && isOffboardingJourney && offboardingEmployeeOption,
    type === 'to' && isCustomJourney && employeeOnJourneyOption,
    type === 'to' && showGroupOption && { value: SPECIFIC_GROUP_ROLE, label: groupDisplayName },
    { value: 'manager', label: I18N('manager') },
    { value: 'specific_employee', label: I18N('specific_employee') },
    !isOffboardingJourney && isOnboardingBuddiesEnabled && { value: 'onboarding_buddy', label: I18N('onboarding_buddy') },
    { value: 'journey_admin', label: I18N('journey_admin') },
  ].filter(Boolean)

  const onChange = ({
    role,
    user = null,
    group = null,
    additionalFields = { notifyViaEmail: true, notifyViaMsTeams: true, notifyViaSlack: true },
  }) => {
    const key = type === 'to' ? 'toCorrespondentAttributes' : 'fromCorrespondentAttributes'
    updateWorkingCopy({
      [key]: { role, user, group },
      ...additionalFields,
    })
  }

  const setGroup = (value) => {
    const additionalFields = {
      notifyViaEmail: false,
      notifyViaMsTeams: false,
      notifyViaSlack: false,
    }
    onChange({ role: 'specific_group', group: value, additionalFields })
  }

  const setUser = (value) => {
    onChange({ role: 'specific_employee', user: value })
  }

  return (
    <div className='CorrespondentSelect'>
      {!showOnlySpecificEmployee && (
        <ReactSelect
          id='fromRoleSelect'
          options={options}
          onChange={selectedOption => onChange({ role: selectedOption?.value })}
          value={options.find(option => option.value === selectedRole)}
          isDisabled={isDisabled}
          className='ClearyReactSelect RoleSelect'
        />
      )}
      {selectedRole === 'specific_employee' && (
        <EmployeeSearch
          className='mt-2 mt-md-0'
          onChange={setUser}
          selectedEmployee={attributes?.user}
          isDisabled={isDisabled}
          isClearable={!showOnlySpecificEmployee}
          showAlertIconForInactiveUsers
        />
      )}

      {selectedRole === 'specific_group' && (
        <ReactSelect
          options={groupOptions}
          getOptionValue={group => group.id}
          getOptionLabel={group => group.name}
          className='GroupCorrespondentSearch mt-2 mt-md-0'
          onChange={setGroup}
          value={attributes?.group ? { id: attributes.group.id, name: attributes.group.name } : null}
          isDisabled={isDisabled}
          isClearable={!showOnlySpecificEmployee}
        />
      )}
    </div>
  )
}

export default CorrespondentSelect
